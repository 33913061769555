// src/App.tsx

import { useState, useEffect } from 'react';
import { supabase } from './utils/supabase';
// Removed bad-words import since we're not using it

type Beer = {
  id: string;
  name: string;
  quantity: number;
};

function App() {
  const [beers, setBeers] = useState<Beer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newBeerName, setNewBeerName] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Fetch beers when the component mounts
    fetchBeers();

    // Cleanup function to clear the debounce timer on unmount
    return () => {
      if (debounceTimer) clearTimeout(debounceTimer);
    };
  }, []);

  // useEffect to handle searchQuery changes with debouncing
  useEffect(() => {
    // Clear any existing debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    const timer = setTimeout(() => {
      fetchBeers();
    }, 500); // 500ms debounce delay

    // Save the timer ID so we can clear it if needed
    setDebounceTimer(timer);

    // Cleanup function to clear the timer when searchQuery changes or component unmounts
    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Fetch beers from Supabase with optional search
  const fetchBeers = async () => {
    setLoading(true);
    setError(null);
    try {
      let query = supabase.from('beers').select('id, name, quantity');

      if (searchQuery.trim() !== '') {
        query = query.ilike('name', `%${searchQuery.trim()}%`);
      }

      const { data, error } = await query;

      if (error) {
        setError('Error fetching beers');
        console.error('Error fetching beers:', error.message);
      } else if (data) {
        setBeers(data as Beer[]);
      }
    } catch (err) {
      setError('Unexpected error occurred');
      console.error('Unexpected error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Handle search input without debouncing logic
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Add a new beer
  const addBeer = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newBeerName.trim()) {
      setError('Please enter a beer name');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('beers')
        .insert([{ name: newBeerName.trim(), quantity: 0 }])
        .select('id, name, quantity');

      if (error) {
        setError('Error adding beer');
        console.error('Error adding beer:', error.message);
      } else if (data && data.length > 0) {
        setBeers([...beers, data[0] as Beer]);
        setNewBeerName('');
      }
    } catch (err) {
      setError('Unexpected error occurred');
      console.error('Unexpected error:', err);
    }
  };

  // Update beer quantity
  const updateBeerQuantity = async (id: string, change: number) => {
    console.log(`updateBeerQuantity called with id: ${id}, change: ${change}`);
    const beer = beers.find((beer) => beer.id === id);
    if (!beer) {
      console.error('Beer not found');
      return;
    }

    const newQuantity = beer.quantity + change;
    if (newQuantity < 0) {
      console.warn('Quantity cannot be negative');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('beers')
        .update({ quantity: newQuantity })
        .eq('id', id)
        .select('id, name, quantity');

      console.log('Supabase update response:', { data, error });

      if (error) {
        setError('Error updating beer quantity');
        console.error('Error updating beer quantity:', error.message);
      } else if (data && data.length > 0) {
        console.log('Update successful:', data[0]);
        setBeers(
          beers.map((b) => (b.id === id ? (data[0] as Beer) : b))
        );
      } else {
        console.error('No data returned after update');
      }
    } catch (err) {
      setError('Unexpected error occurred');
      console.error('Unexpected error:', err);
    }
  };

  // Remove a beer
  const removeBeer = async (id: string) => {
    const confirmDelete = window.confirm('Are you sure you want to remove this beer?');
    if (!confirmDelete) return;

    try {
      const { error } = await supabase
        .from('beers')
        .delete()
        .eq('id', id);

      if (error) {
        setError('Error removing beer');
        console.error('Error removing beer:', error.message);
      } else {
        setBeers(beers.filter((beer) => beer.id !== id));
      }
    } catch (err) {
      setError('Unexpected error occurred');
      console.error('Unexpected error:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white transition-colors duration-500">
      <header className="container mx-auto p-4 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Beer Inventory</h1>
        {/* Remove theme toggle if not needed */}
      </header>

      <main className="container mx-auto p-4">
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Search Bar */}
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search for a beer..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full border border-gray-700 rounded-md p-2 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
          />
          {searchQuery && (
            <button
              onClick={() => setSearchQuery('')}
              className="absolute right-2 top-2 text-gray-400 hover:text-gray-200 focus:outline-none"
              aria-label="Clear search"
            >
              &times;
            </button>
          )}
        </div>

        {/* Form to Add a New Beer */}
        <form onSubmit={addBeer} className="mb-6">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Add a new beer"
              value={newBeerName}
              onChange={(e) => {
                setError(null);
                setNewBeerName(e.target.value);
              }}
              className="border border-gray-700 rounded-md p-2 mr-2 flex-1 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Add Beer
            </button>
          </div>
        </form>

        {loading ? (
          <p>Loading...</p>
        ) : beers.length === 0 ? (
          <p>{searchQuery ? `No beers found for "${searchQuery}"` : 'No beers available'}</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {beers.map((beer) => (
              <div
                key={beer.id}
                className="relative bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col justify-between transition-shadow duration-300 hover:shadow-2xl"
              >
                {/* Delete Button */}
                <button
                  onClick={() => removeBeer(beer.id)}
                  className="absolute top-2 right-2 text-gray-400 hover:text-red-500 focus:outline-none"
                  aria-label={`Remove ${beer.name}`}
                >
                  &times;
                </button>

                <div>
                  <h2 className="text-xl font-semibold mb-2">{beer.name}</h2>
                  <p className="mb-4">Quantity: {beer.quantity}</p>
                </div>

                <div className="flex space-x-2">
                  <button
                    onClick={() => updateBeerQuantity(beer.id, 1)}
                    className="flex-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-300"
                  >
                    Add (+)
                  </button>
                  <button
                    onClick={() => updateBeerQuantity(beer.id, -1)}
                    className="flex-1 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors duration-300"
                  >
                    Remove (-)
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      <footer className="container mx-auto p-4 text-center text-gray-500">
        &copy; {new Date().getFullYear()} Beer Inventory. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
